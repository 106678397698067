import React, { ReactElement } from 'react';

import { TreatmentItem } from '..';
import useSaveAssignment from '../useSaveAssignment';

type TreatmentProps = {
  when: string;
  treatments: Record<string, TreatmentItem>;
  control?: boolean;
  children: React.ReactNode;
};

/**
 * Component to display a treatment
 * @param {String} when
 * @param {Object} treatments
 * @param {Boolean} control
 * @param {Function|Object} children
 * @returns {*}
 */
const Treatment = ({
  when,
  treatments,
  control = false,
  children,
}: TreatmentProps): ReactElement => {
  const saveAssignment = useSaveAssignment();
  const childElement = typeof children === 'function' ? children() : children;
  const allTreatments = Object.keys(treatments);

  // see if the user has been assigned to _any_ treatment. this will be needed if we need to fall back to the control state
  const hasAssignment =
    allTreatments.findIndex(
      treatmentName => treatments[treatmentName].treatmentName
    ) > -1;

  // If experiments fails, or the user hasn't been assigned and experiment show the control.
  if ((allTreatments.length === 0 || !hasAssignment) && control) {
    return childElement;
  }

  // if our treatment exists in the object
  if (allTreatments.length) {
    if (treatments[when] && treatments[when].treatmentName) {
      saveAssignment(treatments[when]);

      // Let's return the child element without waiting for the mutation as the
      // mutation doesn't really impact the children.
      return childElement;
    }
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default Treatment;
