import { useState, useContext } from 'react';
import { StaticContext, clientErrorHandler } from '../../..';

const MAX_LEN = 90;

const useRecaptcha = ({
  actionName,
  siteKey,
  useEnterprise,
  successCallback,
  errorCallback,
}) => {
  const [greToken, setGreToken] = useState('');
  const [showError, setShowError] = useState(false);

  const { recaptchaV3 } = useContext(StaticContext);
  const instanceSiteKey = siteKey || recaptchaV3.siteKey;
  const instanceUseEnterprise = useEnterprise || recaptchaV3.useEnterprise;

  function greSuccessCallback(token) {
    setGreToken(token);
    if (successCallback) {
      successCallback(token);
    }
  }

  function greErrorCallback(err) {
    const reason = String(err);
    const shortReason = reason.substring(0, MAX_LEN);
    clientErrorHandler.logError(
      `Unable to execute. R: ${shortReason}`,
      'gre.getToken.error'
    );

    setShowError(true);
    if (errorCallback) {
      errorCallback(err);
    }
  }

  function executeGRE() {
    let captchaService = window.grecaptcha;
    if (instanceUseEnterprise) {
      captchaService = window.grecaptcha.enterprise;
    }

    captchaService
      .execute(instanceSiteKey, { action: actionName })
      .then(greSuccessCallback, greErrorCallback);
    return true;
  }

  function fireGRE() {
    if (!instanceSiteKey) {
      clientErrorHandler.logError('Missing site_key', 'gre.fire.noSiteKey');
      return false;
    }

    executeGRE(actionName);
    return true;
  }

  return {
    greToken,
    setGreToken,
    showError,
    setShowError,
    fireGRE,
  };
};

export default useRecaptcha;
