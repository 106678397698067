import React from 'react';

import { createURL } from '@sm/utils';
import { t } from '@sm/intl';
import {
  IconUsers,
  IconUserPlus,
  IconUser,
  IconChartDonut,
  IconChartTracker,
  IconWorkgroup,
  IconBlocks,
  IconBooks,
  IconPulse,
  IconMobile,
  IconCart,
  IconGear,
  IconCloudArrow,
} from '@wds/icons';

import TEAM_MENU_COPY from './TeamMenuCopy';

export const ACCOUNT_TYPES = {
  enterpriseAccount: 'enterpriseAccount',
  teamAccount: 'teamAccount',
};

export const USER_ROLES = {
  accountOwner: 'accountOwner',
  admin: 'admin',
  divisionAdmin: 'divisionAdmin',
  user: 'user',
  casual: 'casual',
};

export const SSO_STATE = {
  REQUIRED: 'REQUIRED',
};

export const TEAM_MEMBERSHIP_TYPE = {
  ACCOUNT_OWNER: 'ACCOUNT_OWNER',
  CASUAL: 'CASUAL',
};

export const TAB_IDS = {
  teamSummary: 'teamSummary',
  manageUsers: 'manageUsers',
  addUsers: 'addUsers',
  adminDashboard: 'adminDashboard',
  userReport: 'userReport',
  divisions: 'divisions',
  workgroups: 'workgroups',
  activity: 'activity',
  offlineDevices: 'offlineDevices',
  manageCredits: 'manageCredits',
  manageCreditPools: 'manageCreditPools',
  settings: 'settings',
  library: 'library',
  contributor: 'contributor',
  dei_reporting: 'dei_reporting',
};

export const getTeamMenuItemConfigs = utSource => ({
  [TAB_IDS.teamSummary]: {
    path: createURL('/team/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.TEAM_SUMMARY),
    navItem: 'team summary',
    renderIcon: props => <IconUsers {...props} />,
    id: TAB_IDS.teamSummary,
  },
  [TAB_IDS.manageUsers]: {
    path: createURL('/team/users/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.MANAGE_USERS),
    navItem: 'manage users',
    renderIcon: props => <IconUser {...props} />,
    id: TAB_IDS.manageUsers,
  },
  [TAB_IDS.addUsers]: {
    path: createURL('/team/add/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.ADD_USERS),
    navItem: 'add users',
    renderIcon: props => <IconUserPlus {...props} />,
    id: TAB_IDS.addUsers,
  },
  [TAB_IDS.adminDashboard]: {
    path: createURL('/team/manage/users/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.ADMIN_DASHBOARD),
    navItem: 'admin dashboard',
    renderIcon: props => <IconChartDonut {...props} />,
    id: TAB_IDS.adminDashboard,
  },
  [TAB_IDS.userReport]: {
    path: createURL('/team/user_report/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.USER_REPORT),
    navItem: 'user report',
    renderIcon: props => <IconChartTracker {...props} />,
    id: TAB_IDS.userReport,
  },
  [TAB_IDS.divisions]: {
    path: createURL('/team/divisions/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.DIVISIONS),
    navItem: 'divisions',
    renderIcon: props => <IconBlocks {...props} />,
    id: TAB_IDS.divisions,
  },
  [TAB_IDS.workgroups]: {
    path: createURL('/team/workgroups/all/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.WORKGROUPS),
    navItem: 'workgroups',
    renderIcon: props => <IconWorkgroup {...props} />,
    id: TAB_IDS.workgroups,
  },
  [TAB_IDS.library]: {
    path: createURL('/team/libraries/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.LIBRARY),
    navItem: 'library',
    renderIcon: props => <IconBooks {...props} />,
    id: TAB_IDS.library,
  },
  [TAB_IDS.activity]: {
    path: createURL('/team/activity/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.ACTIVITY),
    navItem: 'activity',
    renderIcon: props => <IconPulse {...props} />,
    id: TAB_IDS.activity,
  },
  [TAB_IDS.offlineDevices]: {
    path: createURL('/team/devices/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.OFFLINE_DEVICES),
    navItem: 'offline devices',
    renderIcon: props => <IconMobile {...props} />,
    id: TAB_IDS.offlineDevices,
  },
  [TAB_IDS.manageCredits]: {
    path: createURL('/team/manage_credits/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.MANAGE_CREDITS),
    navItem: 'manage credits',
    renderIcon: props => <IconCart {...props} />,
    id: TAB_IDS.manageCredits,
  },
  [TAB_IDS.manageCreditPools]: {
    path: createURL('/team/manage_audience_credits/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.MANAGE_CREDITS),
    navItem: 'manage credits',
    renderIcon: props => <IconCart {...props} />,
    id: TAB_IDS.manageCreditPools,
  },
  [TAB_IDS.settings]: {
    path: createURL('/team/settings/', { ut_source: utSource }),
    name: t(TEAM_MENU_COPY.SETTINGS),
    navItem: 'settings',
    renderIcon: props => <IconGear {...props} />,
    id: TAB_IDS.settings,
  },
  [TAB_IDS.dei_reporting]: {
    path: createURL('/team/dei', { ut_source: utSource }),
    name: 'Data upload',
    navItem: 'dei',
    renderIcon: props => <IconCloudArrow {...props} />,
    id: TAB_IDS.dei_reporting,
  },
});
