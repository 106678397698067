import { FontWeightOptionsType } from './types';

export const defaultFontWeightOptions = {
  ultralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
} as const;

type FontDescription = {
  fontName: string;
  fontWeightOptions: FontWeightOptionsType;
};

// Collection of themes with unique font weights.
const fontInfo: FontDescription[] = [
  {
    fontName: 'Montserrat',
    fontWeightOptions: {
      ultralight: 400,
      light: 400,
      regular: 400,
      medium: 400,
      bold: 700,
    },
  },
  {
    fontName: 'Open Sans Condensed',
    fontWeightOptions: {
      ultralight: 300,
      light: 300,
      regular: 300,
      medium: 300,
      bold: 700,
    },
  },
];

const getFontWeights = (fontFamily: string = '') => {
  const fontItem = fontInfo.find(t => t.fontName === fontFamily.split(',')[0]);
  return fontItem ? fontItem.fontWeightOptions : defaultFontWeightOptions;
};

export default getFontWeights;
