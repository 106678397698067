import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { getClientEnvironmentDetails } from '@sm/utils';

import SaveTreatment from './SaveTreatment.graphql';
import { TreatmentItem } from './types';
import { errorHandler } from '../../helpers/errorHandler';
import {
  ExperimentAssignmentType,
  SaveExperimentTreatmentPayload,
} from '../../types';

// until errorHandler gets converted to TS...
type ErrorHandler = {
  getSubscribers: () => string[];
  logError: (err: unknown, message: string) => void;
};

/**
 * Saves the cookie payload from an experimentsvc response
 *
 * @param {Object} saveTreatmentPayload The treatment response from a GraphAPI experimentsvc request
 */
function saveExperimentCookies(
  saveTreatmentPayload: SaveExperimentTreatmentPayload
): void {
  const { isBrowser } = getClientEnvironmentDetails();
  // no need to create a cookie if there isn't a cookie to create
  if (saveTreatmentPayload.name && isBrowser) {
    const { name, maxAge, assignments } = saveTreatmentPayload;
    // remove the subdomain
    const domain = window.location.host.replace(/^[^.]+\./g, '');
    document.cookie = `${name}=${assignments}; max-age=${maxAge}; path=/; domain=.${domain}`;
  }
}

/**
 * Logs an error when attempting to save an experiment assignment
 *
 * @param err The error object to log
 */
function logSaveFailure(err: unknown) {
  (errorHandler as ErrorHandler).logError(
    err,
    'Unable to save experiment treatment'
  );
}

export default function useSaveAssignment() {
  const [saveAssignmentMutation] = useMutation(SaveTreatment, {
    onError: logSaveFailure,
  });
  const [assignmentsSaved, setAssignmentsSaved] = useState<
    Record<string, boolean>
  >({});

  const saveAssignment = useCallback(
    async (treatment: TreatmentItem): Promise<void> => {
      const isSaved = !!assignmentsSaved[treatment.experimentId];

      // a few scenarios where we don't want to fire off a save request
      if (
        // only new assignments need saving...
        treatment.assignmentType === ExperimentAssignmentType.New &&
        // ...if we're already saving/have saved, we don't need to be save _again_
        !isSaved
      ) {
        // we're saving... regardless of outcome, don't try to save again within this browser session
        setAssignmentsSaved({
          ...assignmentsSaved,
          [treatment.experimentId]: true,
        });

        const { data } = await saveAssignmentMutation({
          variables: {
            input: {
              ...treatment,
            },
          },
        });

        if (!data?.saveExperimentTreatment?.success) {
          logSaveFailure(new Error('experimentsvc returned unsuccessful save'));
        } else {
          saveExperimentCookies(data.saveExperimentTreatment);
        }
      }
    },
    [assignmentsSaved, saveAssignmentMutation]
  );

  return saveAssignment;
}
