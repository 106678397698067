import SurveyTheme from './SurveyTheme';
import getFontWeights from './getFontWeights';
import theming, {
  useSurveyTheme,
  createSurveyStyles,
  SurveyThemeProvider,
} from './context';

export {
  SurveyTheme,
  getFontWeights,
  SurveyThemeProvider,
  useSurveyTheme,
  createSurveyStyles,
  theming,
};

export { default as isDark, relativeLuminance } from './isDark';

export type {
  SurveyTheme as SurveyThemeType,
  SurveyPage,
  FontOptions,
  QuestionBody,
  CommonStyle,
  BackgroundOptions,
  Logo,
  ProgressBar,
  FetchTypes,
  FontWeightOptionsType,
} from './types';
