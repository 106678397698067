import React from 'react';
import { useQuery } from '@apollo/client';
import { SurveyThemeProvider } from './context';
import { formatTheme } from './helpers';
import { SurveyTheme as SurveyThemeType } from './types';
import getSurveyDesign from './SurveyTheme.graphql';

type Props = {
  surveyId: string;
  targetEndPoint?: string;
  headers?: Record<string, unknown>;
  children: React.ReactNode;
};

/** A simple wrapper composes inner children with the fetched theming info. */
const SurveyTheme = ({
  children,
  surveyId,
  targetEndPoint,
  headers,
}: Props): JSX.Element | null => {
  const {
    data,
    loading,
    error: fetchError,
    refetch,
  } = useQuery(getSurveyDesign, {
    variables: {
      id: surveyId,
      errorPolicy: 'all',
      partialRefetch: true,
      ...(targetEndPoint && {
        context: {
          targetPath: `/lo-graphql/${targetEndPoint}`,
          ...(headers && headers),
        },
      }),
    },
  });

  /* No-op on the loading state.
   * The wrapping component should know better how to compose with a representable skeleton.
   */
  if (loading) {
    return null;
  }

  const surveyTheme: SurveyThemeType = formatTheme(data);

  return (
    <SurveyThemeProvider
      theme={
        {
          ...surveyTheme,
          ...(fetchError && { refetch, fetchError }),
        } as any
      }
    >
      {children}
    </SurveyThemeProvider>
  );
};

export default SurveyTheme;
