/**
 * SMPackages Locale Module
 * @module @sm/locale
 */
import invariant from 'invariant';
import tldsData from './data/tlds.json';

import {
  GDPRCountryCodes,
  CountryCodes,
  SMSupportedCountryCodes,
  DASHCountryCodes,
  GBCountryCodes,
  USTerritoryCountryCodes,
  EUCountryCodes,
} from './localeSets';
import {
  SITE_WIDE_LOCALE_CODES,
  LANGUAGES,
  SupportLevels,
  LANGUAGES_WITH_SUBDOMAINS,
  LANGUAGES_BY_CODE,
  LanguageDefinition,
  LanguageCode,
  SiteWideLocaleCode,
} from './languages';
import { DataCenterIds, getDataCenterIdFromCurrentUrl } from './dataCenters';

// This is for backward compatibility. Remove for 2.0
export const localeSets = {
  GDPR_COUNTRIES: GDPRCountryCodes,
  SM_SUPPORTED_COUNTRIES: SMSupportedCountryCodes,
  EU_COUNTRIES: EUCountryCodes,
  US_TERRITORIES: USTerritoryCountryCodes,
  GB_COUNTRIES: GBCountryCodes,
  DASH_COUNTRIES: DASHCountryCodes,
};

export {
  getDataCenterById,
  getDataCenterIdFromCurrentUrl,
  getDefaultSubdomainForDataCenter,
} from './dataCenters';

export {
  LANGUAGES_BY_CODE,
  LANGUAGES_BY_ID,
  LANGUAGES,
  SITE_WIDE_LOCALE_CODES,
  SURVEY_CREATION_LOCALE_CODES,
  MULTILINGUAL_SURVEYS_LOCALE_CODES,
  SupportLevels,
  LanguageCode,
  SiteWideLocaleCode,
  SurveyCreationLocaleCode,
  MultilingualSurveysLocaleCode,
} from './languages';

export {
  getLanguageTags,
  getLanguageTagById,
  getLanguageTagByCode,
  parseLanguageTag,
  convertLanguageTag,
  normalizeLanguageTag,
} from './languageTags';

/**
 * @param {string} country - code
 * @param {string} fallBackCountry - default country code
 * @returns {string} two letter country code, default to US if a fallback is not provided
 */
export function sanitizeCountryCode(
  country: string,
  fallBackCountry = CountryCodes.US
): CountryCodes {
  const isString = typeof country === 'string';
  if (!isString) {
    // TODO log this
    invariant(false, 'Country code is not a string.');
    return fallBackCountry;
  }

  const isTwoLetterCode = country.length === 2;
  if (!isTwoLetterCode) {
    // TODO log this
    invariant(false, 'Country code is not a 2 letter code');
    return fallBackCountry;
  }
  return (
    SMSupportedCountryCodes.find(c => c === country.toUpperCase()) ||
    (fallBackCountry.toUpperCase() as CountryCodes)
  );
}

/**
 * @param {string} country - Two-letter country code
 * @returns {bool} if that country has GDPR laws
 */
export function isGDPRCountry(country: string): boolean {
  // if we cannot determine the country, assume GDPR country ie GB
  return GDPRCountryCodes.includes(
    sanitizeCountryCode(country, CountryCodes.GB)
  );
}

/**
 * @param {string} country - Two-letter country code
 * @returns {bool} if country is in DASH country set
 */
export function isDACHCountry(country: string): boolean {
  // if we cannot determine the country, assume non Dash ie. US
  return DASHCountryCodes.includes(
    sanitizeCountryCode(country, CountryCodes.US)
  );
}

/**
 * @param {string} country - Two-letter country code
 * @returns {bool} if country is in the United Kingdoms
 * @public
 */
export function isGBCountry(country: string): boolean {
  // if we cannot determine the country, assume GB country
  return GBCountryCodes.includes(sanitizeCountryCode(country, CountryCodes.GB));
}

/**
 * @param {string} country - Two-letter country code
 * @returns {bool} if country is a US Territory
 * @public
 */
export function isUSTerritory(country: string): boolean {
  // if we cannot determine the country, assume Non US
  return USTerritoryCountryCodes.includes(
    sanitizeCountryCode(country, CountryCodes.GB)
  );
}

/**
 * @param {string} country - Two-letter country code
 * @returns {bool} if country is in European Union
 * @public
 */
export function isEUCountry(country: string): boolean {
  // if we cannot determine the country, assume EU. (joelm: WHY?!?!?!)
  return EUCountryCodes.includes(sanitizeCountryCode(country, CountryCodes.GB));
}

/**
 * @param {string} hostname
 * @returns {bool} if host is a EUDC
 * @public
 */
export function isEUDC(hostname: string): boolean {
  return getDataCenterIdFromCurrentUrl(hostname) === DataCenterIds.EU;
}

/**
 * @param {string} subdomain
 * @returns {bool} if EU Subdomain
 * @public
 */
export function isEUSubdomain(subdomain: string): boolean {
  return !!(subdomain && subdomain.indexOf('eu') !== -1);
}

/**
 * @returns {Array} list of European Union Countries
 * @public
 */
export function getEUCountries(): CountryCodes[] {
  return [...EUCountryCodes];
}

/**
 * @returns {Array} List of SM supported TLDs
 * @public
 */
export function getCookieTransportTLDs(): string[] {
  return tldsData.filter(tld => tld.enabled).map(tld => tld.tld);
}

/**
 * @returns {Array} Countries that SM Audience supports
 * @public
 */
export function getAudienceSupportedCountries(): Record<string, string> {
  // TODO change this to just return list of country codes
  return {
    US: 'United States',
    AU: 'Australia',
    CA: 'Canada',
    NZ: 'New Zealand',
    SG: 'Singapore',
    GB: 'United Kingdom',
    IE: 'Ireland',
  };
}

/**
 * @returns {Array} Countries that SM Benchmarks supports
 * @public
 */
export function getBenchmarksSupportedCountries(): Record<string, string> {
  // TODO change this to jst return a list of country codes
  return {
    US: 'United States',
  };
}

/**
 * @returns {Array} Countries that SM supports
 * @public
 */
export function getSMSupportedCountries(): CountryCodes[] {
  return [...SMSupportedCountryCodes];
}

/**
 * @returns {Object} Language Object that SM Supports
 * @public
 */
export function getSiteSupportedLanguages(): LanguageDefinition[] {
  return LANGUAGES.filter(
    language => language.supportLevel === SupportLevels.SITE_WIDE
  );
}

/**
 * @param {string} country - Two-letter country code
 * @returns {string} language code
 */
export function getLanguageCodeFromCountryCode(
  country: CountryCodes
): LanguageCode {
  const language = LANGUAGES.find(lang =>
    lang.countries.includes(sanitizeCountryCode(country))
  );
  return language ? language.code : 'en';
}

/**
 * Returns the language code from the subdomain
 * @param {string} subdomain
 * @returns {string} language code
 */
export function getLanguageCodeFromSubdomain(subdomain: string): LanguageCode {
  if (subdomain === 'eu' || subdomain === 'www') {
    return 'en';
  }
  const language = LANGUAGES_WITH_SUBDOMAINS.find(
    lang => lang.subdomain === subdomain || `${lang.subdomain}.eu` === subdomain
  );
  if (language) {
    return language.code;
  }
  return 'en';
}

/**
 * Returns the subdomain from the language code and data center combination
 * @param {string} languageCode
 * @param {string} dataCenterId
 * @returns {string} subdomain
 */
export function getSubdomainFromLanguageCodeAndDataCenter(
  languageCode: string,
  dataCenterId: DataCenterIds
): string | undefined {
  const language = LANGUAGES_BY_CODE[languageCode];
  let result = language.subdomain;
  if (dataCenterId === DataCenterIds.EU) {
    result = `${language.subdomain}.eu`;
  }
  return result === 'www.eu' ? 'eu' : result;
}

/**
 * @returns {Array} Language codes that SM Supports
 * @public
 */
export function getSiteSupportedLanguageCodes(): readonly SiteWideLocaleCode[] {
  return SITE_WIDE_LOCALE_CODES;
}

type TLDInfo = {
  tld: string;
  defaultLanguage: string;
  supportedLanguages: string[];
  enabled: boolean;
};

/**
 * Returns the tld language info
 * @param {string} tld
 * @returns {string} language info
 */
export function getTldLanguageInfo(tld: string): TLDInfo | undefined {
  const data = tldsData.find(i => i.tld === tld);
  if (!data) {
    return tldsData.find(i => i.tld === 'com');
  }
  return data;
}
