import { WrenchTheme } from '@wds/styles';
import {
  ColorPalette,
  CommonStyle,
  FontStyleType,
  FontWeightType,
  QuestionBodyStyle,
  SurveyLogo,
  SurveyPageStyle,
  SurveyProgressBar,
  TextDecorationType,
  GetSurveyDesignResponse,
} from './graphQlApiResponse.types';
import isDark from './isDark';
import getFontWeights, {
  defaultFontWeightOptions as fontWeightOption,
} from './getFontWeights';
import { SurveyTheme as SurveyThemeType, FontWeightOptionsType } from './types';
import { FontStyle } from '../../types';

const fontStyleOption = {
  INHERIT: 'inherit',
  INITIAL: 'initial',
  ITALIC: 'italic',
  NORMAL: 'normal',
  OBLIQUE: 'oblique',
} as const;

const textDecorationOption = {
  NONE: 'none',
  UNDERLINE: 'underline',
} as const;

export const breakpoints = {
  xxs: { max: '480px' },
  xs: { min: '481px', max: '640px' },
  sm: { min: '641px', max: '768px' },
  md: { min: '769px', max: '1024px' },
  lg: { min: '1025px', max: '1200px' },
  xl: { min: '1201px' },
} as const;

/** Possible Styles to format */
type Styles =
  | CommonStyle
  | SurveyPageStyle
  | QuestionBodyStyle
  | SurveyProgressBar
  | SurveyLogo
  | ColorPalette;

/**
 * Generic helper to map an Object to a discriminated union of key-value tuples
 *
 * `{a: number, b?: string | null}` -> `["a", number] | ["b", string | null | undefined]`
 */
type MapKVToTupleUnion<T extends Record<string, unknown>> = NonNullable<
  {
    [K in keyof T]: [K, T[K]];
  } extends Record<string, infer B>
    ? B
    : never
>;

/**
 * Generic helper to map an Object to a discriminated union of key-value tuples and make the keys non-nullable
 *
 * `{a: number, b?: string | null}` -> `["a", number] | ["b", string]`
 */
type MapKVToNonNullableTupleUnion<T extends Record<string, unknown>> =
  NonNullable<
    {
      [K in keyof T]: [K, NonNullable<T[K]>];
    } extends Record<string, infer B>
      ? B
      : never
  >;

type StyleTuples = MapKVToNonNullableTupleUnion<Styles>;

const formatStyle = <T extends FontStyle>(kvPair: StyleTuples, styles: T) => {
  switch (kvPair[0]) {
    case 'fontWeight':
      if (styles.fontFamily) {
        const fontWeights = getFontWeights(styles.fontFamily);
        return {
          [kvPair[0]]:
            fontWeights[kvPair[1].toLowerCase() as keyof FontWeightOptionsType],
        };
      }
      return {
        [kvPair[0]]:
          fontWeightOption[
            kvPair[1].toLowerCase() as keyof FontWeightOptionsType
          ],
      };
    case 'fontStyle':
      return {
        [kvPair[0]]: fontStyleOption[kvPair[1]],
      };
    case 'fontFamily':
      if (kvPair[1] === 'National2') {
        // add fallback-fonts and also use Wrench spelling of font: `'National 2'` vs `National2`
        return {
          [kvPair[0]]: WrenchTheme.type.fontFamily.base,
        };
      }
      return {
        [kvPair[0]]: kvPair[1],
      };
    case 'textDecoration':
      return {
        [kvPair[0]]: textDecorationOption[kvPair[1]],
      };
    case 'backgroundImage':
      return kvPair[1].url
        ? {
            [kvPair[0]]: `url(${kvPair[1].url})`,
          }
        : null;
    default:
      if (typeof kvPair[1] === 'object') {
        // format nested data recursively
        return { [kvPair[0]]: formatCommonStyle(kvPair[1]) };
      }
      return { [kvPair[0]]: kvPair[1] };
  }
};

export type FontWeightOptions =
  typeof fontWeightOption[Lowercase<FontWeightType>];
export type FontStyleOptions = typeof fontStyleOption[FontStyleType];
export type TextDecorationOptions =
  typeof textDecorationOption[TextDecorationType];

type MappedFields =
  | 'fontWeight'
  | 'fontStyle'
  | 'textDecoration'
  | 'backgroundImage';
type PickResult<T extends Styles> = {
  [Key in keyof T as Exclude<Key, MappedFields>]: NonNullable<T[Key]>;
} & (T extends Pick<CommonStyle, 'fontWeight'>
  ? { fontWeight?: FontWeightOptions }
  : {}) &
  (T extends Pick<CommonStyle, 'fontStyle'>
    ? { fontStyle?: FontStyleOptions }
    : {}) &
  (T extends Pick<CommonStyle, 'textDecoration'>
    ? { textDecoration?: TextDecorationOptions }
    : {}) &
  (T extends Pick<CommonStyle, 'backgroundImage'>
    ? { backgroundImage?: string }
    : {});

/** Formats data recursively */
const formatCommonStyle = <T extends Styles>({
  __typename,
  ...styles
}: T & { __typename?: string }): PickResult<T> => {
  const entries = Object.entries(styles) as MapKVToTupleUnion<T>[];

  return entries.reduce((acc, args) => {
    const [key, val] = args as StyleTuples;
    return val === null || val === undefined
      ? acc
      : {
          ...acc,
          ...formatStyle([key, val], styles),
        };
  }, {} as PickResult<T>);
};

/** Standardizes the formatting to be CSS/JSS compatible  */
export const formatEntity = <T extends Styles>(data?: T | null) =>
  (data && formatCommonStyle(data)) || undefined;

/** Collapses `Maybe<T>` (`T | null | undefined`) to `T | undefined` */
export const defaultNullToUndefined = <T>(arg: T) =>
  arg === null ? undefined : (arg as T extends null ? never : T);

/** Format GraphQL to Theme representation */
export const formatTheme = (
  response: GetSurveyDesignResponse = {}
): SurveyThemeType => {
  const { theme, ...settings } = response?.survey?.design || {};

  const {
    name = 'Default',
    selectedColorPalette,
    layout,
    surveyPage,
    surveyTitle,
    pageTitle,
    questionTitle,
    questionBody,
    button,
    error,
    logoStyle,
    progressBarStyle,
    progressBarIndicator,
    persistentProgressBar,
    pageDescription,
    exitLink,
    artifacts,
    isAccessible,
    isCustomTheme,
  } = theme || {};

  const { logo, progressBar } = settings;

  const surveyPageBackgroundImage = artifacts?.background?.url;
  const smFont =
    "'National 2', 'Helvetica Neue', Helvetica, Arial, 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'ＭＳ ゴシック', 'MS Gothic', sans-serif";

  const surveyTheme: SurveyThemeType = {
    name,
    fontFamily: smFont,
    fontSize: {
      body: 16,
    },
    fontWeight: fontWeightOption,
    layout: defaultNullToUndefined(layout),
    isAccessible: isAccessible && !isCustomTheme,
    isCustomTheme,
    isDark: isDark(selectedColorPalette?.primaryBackgroundColor || '#fff'),
    surveyPage: {
      ...formatEntity(surveyPage),
      ...(surveyPageBackgroundImage && {
        backgroundImage: `url(${surveyPageBackgroundImage})`,
      }),
    },
    surveyTitle: formatEntity(surveyTitle),
    pageTitle: formatEntity(pageTitle),
    questionTitle: formatEntity(questionTitle),
    questionBody: {
      color: selectedColorPalette?.answerColor,
      ...formatEntity(questionBody),
    },
    button: formatEntity(button),
    error: formatEntity(error),
    exitLink: formatEntity(exitLink),
    ...(logo && {
      logo: formatEntity(logo),
    }),
    ...(progressBar && {
      progressBar: formatEntity(progressBar),
    }),
    ...(logoStyle && {
      logoStyle: formatEntity(logoStyle),
    }),
    ...(progressBarStyle && {
      progressBarStyle: formatEntity(progressBarStyle),
    }),
    ...(progressBarIndicator && {
      progressBarIndicator: { ...formatEntity(progressBarIndicator) },
    }),
    ...(persistentProgressBar && {
      persistentProgressBar: { ...formatEntity(persistentProgressBar) },
    }),
    ...(pageDescription && {
      pageDescription: { ...formatEntity(pageDescription) },
    }),
    ...formatEntity(selectedColorPalette),
    breakpoints,
  };

  return surveyTheme;
};
