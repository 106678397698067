/**
 * Relative luminance of a color
 * from http://www.w3.org/TR/WCAG20/#relativeluminancedef
 */
const relativeLuminanceInner = (
  R8bit: number,
  G8bit: number,
  B8bit: number
): number => {
  const RsRGB = R8bit / 255;
  const GsRGB = G8bit / 255;
  const BsRGB = B8bit / 255;

  const R = RsRGB <= 0.03928 ? RsRGB / 12.92 : ((RsRGB + 0.055) / 1.055) ** 2.4;
  const G = GsRGB <= 0.03928 ? GsRGB / 12.92 : ((GsRGB + 0.055) / 1.055) ** 2.4;
  const B = BsRGB <= 0.03928 ? BsRGB / 12.92 : ((BsRGB + 0.055) / 1.055) ** 2.4;

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

const hexRegex = /^#[a-fA-F0-9]{6}$/;
const reducedHexRegex = /^#[a-fA-F0-9]{3}$/;
const rgbOrRgbaRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3}).*\)$/i;

/**
 * Convert RGB, RGBA or Hex into relative luminance
 *
 * Based on http://www.w3.org/TR/WCAG20/#relativeluminancedef
 *
 * @param rgbaOrHex RGB, RGBA or Hex color (`rgb(255,255,255)`, `rgba(255,255,255,1)`, `#FFF`, `#FFFFFF`)
 * @returns {number} number between `0` and `1`;
 */
export const relativeLuminance = (rgbaOrHex: string): number => {
  const color = rgbaOrHex ?? '';
  const rgbMatched = (color ?? '').match(rgbOrRgbaRegex);
  if (rgbMatched) {
    return relativeLuminanceInner(
      parseInt(`${rgbMatched[1]}`, 10),
      parseInt(`${rgbMatched[2]}`, 10),
      parseInt(`${rgbMatched[3]}`, 10)
    );
  }
  if (color.match(hexRegex)) {
    return relativeLuminanceInner(
      parseInt(`${color[1]}${color[2]}`, 16),
      parseInt(`${color[3]}${color[4]}`, 16),
      parseInt(`${color[5]}${color[6]}`, 16)
    );
  }
  if (color.match(reducedHexRegex)) {
    return relativeLuminanceInner(
      parseInt(`${color[1]}${color[1]}`, 16),
      parseInt(`${color[2]}${color[2]}`, 16),
      parseInt(`${color[3]}${color[3]}`, 16)
    );
  }
  throw Error('relativeLuminance: Invalid color format');
};

/**
 * Determines if a color is considered "dark" - as in dark theme
 *
 * @param color RGB, RGBA or Hex color (`rgb(255,255,255)`, `rgba(255,255,255,1)`, `#FFF`, `#FFFFFF`)
 * @returns {boolean} `true` if the color is considered "dark"
 */
const isDark = (color: string) => relativeLuminance(color) < 0.5;

export default isDark;
