import { FC, ReactNode } from 'react';

import { clientErrorHandler } from '../..';

interface urlObject {
  smReleaseToggles?: string;
}

interface cookieObject {
  sm_release_toggles?: string;
}

type url = string | urlObject;
type cookies = string | cookieObject;

export interface ReleaseToggleProps {
  toggleId: string;
  url?: url;
  cookies?: cookies;
  children: ReactNode;
}

/**
 * Takes the cookie string from the document and returns an Array of
 * configured release toggle IDs using the key 'sm_release_toggles'.
 *
 * @param {string} cookie: Cookie string from document
 * @return {Array}
 */
export const getOverridesFromCookie = (cookie?: string) => {
  if (!cookie) return [];
  const found = cookie
    .split(';')
    .filter(elem => elem)
    .map(elem => elem.split('='))
    .filter(([key, val]) => key && val)
    .map(([key, val]) => ({ key: key.trim(), val: val.trim() }))
    .find(elem => elem.key === 'sm_release_toggles');

  const toggles: Array<string> = [];
  if (found) {
    found.val.split(',').forEach(elem => {
      toggles.push(elem.trim());
    });
  }
  return toggles;
};

/**
 * Takes a URL string from the window.location object (must include query
 * params) and returns an Array of the configured release toggle IDs under the
 * key 'smReleaseToggles'.
 *
 * @param {string} url: HREF string from the window.location object
 * @return {*}
 */
export const getOverridesFromQueryParameters = (url?: string) => {
  if (!url) return [];
  const queryStrIndex = url.indexOf('?');
  if (queryStrIndex > -1) {
    const queryStr = url.slice(queryStrIndex + 1);
    const releaseToggles = queryStr
      .split('&')
      .map(elem => elem.split('='))
      .find(elem => {
        const [key] = elem;
        return key === 'smReleaseToggles';
      });
    if (releaseToggles && releaseToggles.length > 1) {
      return releaseToggles[1].split(',').filter(elem => elem);
    }
  }
  return [];
};

/**
 * Looks for a 'sm_release_toggles' cookie. If it exists, parses out the
 * comma-separated list of toggle IDs and returns a Set of the IDs.
 *
 * @return {Set}: IDs of enabled toggles
 */
export const getOverrides = (url?: url, cookies?: cookies) => {
  // We know that this is from the Express req.
  if (typeof url === 'object' && typeof cookies === 'object') {
    const paramValues = (url.smReleaseToggles || '')
      .split(',')
      .map(elem => elem.trim());
    const cookieValues = (cookies.sm_release_toggles || '')
      .split(',')
      .map(elem => elem.trim());
    return new Set(paramValues.concat(cookieValues));
  }
  return new Set(
    getOverridesFromCookie(document.cookie).concat(
      getOverridesFromQueryParameters(window.location.href)
    )
  );
};

/**
 * Component wraps code that isn't ready to be seen. Children are only
 * rendered if the 'releaseToggles' cookie or query parameter is set
 * where the value is a comma-separated list of toggle IDs
 * (IE. document.cookie="releaseToggles=ID1,ID2,ID3", whitespace is trimmed;
 * or https://localmonkey.com/my_page/?smReleaseToggles=ID1,ID2,ID3, no
 * whitespace trim) and the ID is in the list. If this component is rendered
 * on the client, these values are manually parsed from the window. If not,
 * they must be passed in through the queryParams and cookies props.
 *
 * Component may also have a child that is a function with the signature:
 * (isToggledOn: boolean) => Node which can be used to render a default
 * component in place of the feature component.
 *
 * If there is an error parsing the override cookies or params, it is caught,
 * logged and then the child function is called with false or null is returned.
 */
const ReleaseToggle: FC<ReleaseToggleProps> = ({
  toggleId,
  url,
  cookies,
  children,
}) => {
  let showToggle;
  try {
    showToggle = getOverrides(url, cookies).has(toggleId);
  } catch (err) {
    showToggle = false;
    // TODO: Remove this cast when clientErrorHandler has been typed
    (clientErrorHandler as any).logError(
      err,
      'There was a problem reading the override cookies and params in a ReleaseToggle'
    );
  }
  if (typeof children === 'function') return children(showToggle);
  return showToggle ? children : null;
};

export default ReleaseToggle;
