import { useQuery } from '@apollo/client';
import { getClientEnvironmentDetails } from '@sm/utils';
import { useCallback, useMemo } from 'react';

import experimentsQuery from './Experiments.graphql';
import {
  Experiment,
  TreatmentComparator,
  TreatmentItem,
  TreatmentLookupTable,
  UseExperimentArgs,
  UseExperimentResult,
} from './types';
import useSaveAssignment from './useSaveAssignment';

/**
 * Converts an experimentsvc experiment response to a TreatmentItem object
 *
 * @param experiment The experiment object from experimentsvc to format
 * @returns The formatted TreatmentItem object
 */
function formatExperimentAsTreatmentItem(
  experiment: Experiment
): TreatmentItem {
  return {
    treatmentName: experiment.treatment.name,
    treatmentId: experiment.treatment.id,
    experimentId: experiment.id,
    experimentName: experiment.name,
    assignmentType: experiment.assignmentType,
  };
}

export default function useExperiment({
  name,
  treatmentNames,
  controlName,
}: UseExperimentArgs): UseExperimentResult {
  const { isBrowser } = getClientEnvironmentDetails();

  const pageReferer = isBrowser ? document.referrer : '/';
  const pageUrl = isBrowser ? window.location.href : '/';

  // convert the name to an array without whitespace if it is not already...
  // ...which it shouldn't be because types, but we can't assume TS everywhere
  const experimentNames = Array.isArray(name) ? name : [name.trim()];

  const { data, loading } = useQuery(experimentsQuery, {
    fetchPolicy: 'no-cache',
    variables: { pageReferer, pageUrl, experimentNames },
  });

  const saveAssignment = useSaveAssignment();

  const { treatmentNameMap, experimentName, assignment } = useMemo(() => {
    const treatmentMap: TreatmentLookupTable = {};
    let returnedAssignment: TreatmentItem | undefined;

    if (!loading && Array.isArray(data?.experiments)) {
      // load up the actual real data that we have...
      data.experiments.forEach((exp: Experiment) => {
        const treatmentItem = formatExperimentAsTreatmentItem(exp);
        treatmentMap[exp.treatment.name] = treatmentItem;
        returnedAssignment = treatmentItem;
      });

      // ...and toss in some perfunctory empty objects for any other treatments that were
      // requested from the hook but not returned from the server
      [...(treatmentNames ?? []), controlName].forEach(treatmentName => {
        if (treatmentName) {
          treatmentMap[treatmentName] = treatmentMap[treatmentName] ?? {};
        }
      });
    }

    return {
      treatmentNameMap: treatmentMap,
      experimentName: returnedAssignment?.experimentName,
      assignment: returnedAssignment,
    };
  }, [controlName, data, loading, treatmentNames]);

  const getAssignment = useCallback<() => TreatmentItem | null>(() => {
    if (loading) {
      return null;
    }

    // if the assignment object is trying to be accessed, the experiment is being acted upon,
    // so ensure that the assignment gets saved
    if (assignment) {
      saveAssignment(assignment);
    }

    // return a copy of the object so folks on the other side can't do shenanigans
    return assignment ? { ...assignment } : null;
  }, [loading, assignment, saveAssignment]);

  const isTreatment = useCallback<TreatmentComparator>(
    (treatmentName: string) => {
      if (loading) {
        return false;
      }

      const activeAssignment = getAssignment();
      return activeAssignment?.treatmentName === treatmentName;
    },
    [loading, getAssignment]
  );

  const isControl = useCallback((): boolean => {
    // if no control treatment name was provided, we can't meaningfully determine this condition
    if (!controlName) {
      throw new Error('No control treatment name provided');
    }

    if (loading) {
      return false;
    }

    // no assignment or assigned to control is control for functional purposes
    return !assignment || isTreatment(controlName);
  }, [controlName, loading, assignment, isTreatment]);

  // we only support one experiment at a time... for now. A multi-experiment
  // hook will need a completely different shape
  if (experimentNames.length > 1) {
    throw new Error('useExperiment only supports loading one experiment');
  }

  return {
    name: experimentName,
    treatments: treatmentNameMap,
    loading,

    isControl,
    isTreatment,
    getAssignment,
  };
}
