import { clientErrorHandler } from '../..';

import {
  getTeamMenuItemConfigs,
  TAB_IDS,
  ACCOUNT_TYPES,
  USER_ROLES,
  SSO_STATE,
  TEAM_MEMBERSHIP_TYPE,
} from './teamMenuConstants';

// build the submenu items based on the current user package and role permissions
const _getSubMenuItems = ({ menuConfigs, utSource }) => {
  const menuItemConfigs = getTeamMenuItemConfigs(utSource);

  const menuItems = [
    menuConfigs.canAccess.teamSummaryTab &&
      menuItemConfigs[TAB_IDS.teamSummary],
    menuConfigs.canAccess.manageUsersTab &&
      menuItemConfigs[TAB_IDS.manageUsers],
    menuConfigs.canAccess.addUsersTab && menuItemConfigs[TAB_IDS.addUsers],
    menuConfigs.canAccess.adminDashboardTab &&
      menuItemConfigs[TAB_IDS.adminDashboard],
    menuConfigs.canAccess.userReportTab && menuItemConfigs[TAB_IDS.userReport],
    menuConfigs.canAccess.divisionsTab && menuItemConfigs[TAB_IDS.divisions],
    menuConfigs.canAccess.workgroupsTab && menuItemConfigs[TAB_IDS.workgroups],
    menuConfigs.canAccess.libraryTab && menuItemConfigs[TAB_IDS.library],
    menuConfigs.canAccess.activityTab && menuItemConfigs[TAB_IDS.activity],
    menuConfigs.canAccess.offlineDevicesTab &&
      menuItemConfigs[TAB_IDS.offlineDevices],
    menuConfigs.canAccess.manageCreditsTab &&
      menuItemConfigs[TAB_IDS.manageCredits],
    menuConfigs.canAccess.manageCreditPoolsTab &&
      menuItemConfigs[TAB_IDS.manageCreditPools],
    menuConfigs.canAccess.settingsTab && menuItemConfigs[TAB_IDS.settings],
    menuConfigs.canAccess.deiReportingTab &&
      menuItemConfigs[TAB_IDS.dei_reporting],
  ];
  return menuItems.filter(Boolean);
};

// spell out all the combination of userPackage/role
// to better understand what the user can and cant see in the ui
const _buildMenuConfigs = ({
  features,
  isSSOEnabled = false,
  isAdminImpersonated = false,
  divisionAdminsCanManageUsers = false,
  permissions: {
    audienceCreditsManage: audienceCreditsManagePermission,
    audienceCreditsManageCreditPools:
      audienceCreditsManageCreditPoolsPermission,
    teamSummary: teamSummaryPermission,
    manageUsers: manageUsersPermission,
    addUsers: addUsersPermission,
    division: divisionPermission,
    workgroup: workgroupPermission,
    activity: activityPermission,
    offlineDevices: offlineDevicesPermission,
    adminDashboard: adminDashboardPermission,
    userReport: userReportPermission,
    library: libraryPermission,
  },
}) => {
  const defaultTabs = {
    teamSummaryTab: teamSummaryPermission,
    manageUsersTab: manageUsersPermission,
    addUsersTab: addUsersPermission && !isSSOEnabled,
    // SSO can't see Add Users tab, only button on header
    adminDashboardTab:
      features.adminDashboardEnabled &&
      adminDashboardPermission &&
      !userReportPermission,
    userReportTab: userReportPermission,
    divisionsTab: features.divisionsEnabled && divisionPermission,
    workgroupsTab: features.workgroupsEnabled && workgroupPermission,
    activityTab: features.auditLogsEnabled && activityPermission,
    offlineDevicesTab:
      features.offlineEnterpriseEnabled && offlineDevicesPermission,
    manageCreditsTab:
      features.teamAudienceCreditsEnabled &&
      audienceCreditsManagePermission &&
      !features.audienceCreditPoolsEnabled &&
      !audienceCreditsManageCreditPoolsPermission,
    manageCreditPoolsTab:
      features.audienceCreditPoolsEnabled &&
      audienceCreditsManageCreditPoolsPermission,
    libraryTab: libraryPermission,
    settings: false,
  };

  return {
    enterpriseAccount: {
      [`${USER_ROLES.accountOwner}`]: {
        canAccess: {
          ...defaultTabs,
          activityTab: defaultTabs.activityTab || isAdminImpersonated,
          deiReportingTab: features.deiReporting,
          settingsTab: true,
        },
      },
      [`${USER_ROLES.admin}`]: {
        canAccess: {
          ...defaultTabs,
          activityTab: defaultTabs.activityTab || isAdminImpersonated,
          deiReportingTab: features.deiReporting,
          settingsTab: true,
        },
      },
      [`${USER_ROLES.divisionAdmin}`]: {
        canAccess: {
          ...defaultTabs,
          manageUsersTab:
            defaultTabs.manageUsersTab || divisionAdminsCanManageUsers,
          activityTab: defaultTabs.activityTab || isAdminImpersonated,
          deiReportingTab: features.deiReporting,
        },
      },
      [`${USER_ROLES.user}`]: {
        canAccess: {
          ...defaultTabs,
          deiReportingTab: features.deiReporting,
        },
      },
      [`${USER_ROLES.casual}`]: {
        canAccess: defaultTabs,
      },
    },
    teamAccount: {
      [`${USER_ROLES.accountOwner}`]: {
        canAccess: {
          ...defaultTabs,
          deiReportingTab: features.deiReporting,
        },
      },
      [`${USER_ROLES.admin}`]: {
        canAccess: {
          ...defaultTabs,
          deiReportingTab: features.deiReporting,
        },
      },
      [`${USER_ROLES.user}`]: {
        canAccess: {
          ...defaultTabs,
          deiReportingTab: features.deiReporting,
        },
      },
    },
  };
};

const _getMenuItems = ({ user, session, utSource, permissions }) => {
  const team = user.team || {};
  const teamMembership = user.teamMembership || {};
  // I will work on conforming the mobile and desktop calls because
  // session is passed in in 2 different ways currently
  const userSession = session || user.session;
  const isAdminImpersonated = userSession && userSession.isAdminImpersonation;
  const { isAdmin, isDivisionAdmin } = teamMembership;
  const isSSOEnabled = team.ssoState === SSO_STATE.REQUIRED;
  const isTeamOwner =
    teamMembership.memberType === TEAM_MEMBERSHIP_TYPE.ACCOUNT_OWNER;
  const isCasual = teamMembership.memberType === TEAM_MEMBERSHIP_TYPE.CASUAL;
  const features = user.features || {};
  const isEnterpriseAccount = teamMembership.isEnterprise;
  const isTeamAccount = !teamMembership.isEnterprise;
  const divisionAdminsCanManageUsers =
    user?.team?.settings?.divisionAdminsCanManageUsers ?? false;

  let userAccountType = '';
  if (isEnterpriseAccount) {
    // enterprise package 26 accounts
    userAccountType = ACCOUNT_TYPES.enterpriseAccount;
  } else if (isTeamAccount) {
    // self serve teams
    userAccountType = ACCOUNT_TYPES.teamAccount;
  }

  let userRole = '';
  if (isTeamOwner) {
    userRole = USER_ROLES.accountOwner;
  } else if (isAdmin) {
    userRole = USER_ROLES.admin;
  } else if (isDivisionAdmin) {
    userRole = USER_ROLES.divisionAdmin;
  } else if (isCasual) {
    userRole = USER_ROLES.casual;
  } else {
    userRole = USER_ROLES.user;
  }

  const validUserAccountAndRole = !!userAccountType && !!userRole;
  if (validUserAccountAndRole) {
    try {
      const menuConfigs = _buildMenuConfigs({
        features,
        isSSOEnabled,
        isAdminImpersonated,
        divisionAdminsCanManageUsers,
        permissions,
      })[userAccountType][userRole];
      // return only menu items the user have access to based on their
      // package/role
      return _getSubMenuItems({ menuConfigs, utSource });
    } catch (e) {
      // This should not occur, however it is a fallback in case we introduce
      // some new user account type
      clientErrorHandler.logError(
        e,
        'Unexpected error while building My Team menu items',
        {
          userAccountType,
          userRole,
          features,
          isSSOEnabled,
          isAdminImpersonated,
        }
      );

      return [];
    }
  }
  return [];
};

const getMyTeamMenuItems = ({
  user,
  session = {},
  utSource = 'header',
  permissions = {},
}) => {
  if (!user) {
    return [];
  }

  return _getMenuItems({ user, session, utSource, permissions });
};

export default getMyTeamMenuItems;
