export const HEADER_VARIANT = {
  DEFAULT: 'default',
  LIMITED: 'limited',
  SLIM: 'slim',
};

export const HEADER_COLOR = {
  PRIMARY: 'primary',
  LIGHT: 'light',
  TRANSPARENT: 'transparent',
};
